import React, { useState } from 'react';
import emojione from 'emojione';
import './emoji.css';

emojione.imageType = "svg";

const Starx = () => {
  const [modalVisible, setModalVisible] = useState(false);

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleReactionClick = (event) => {
  const emojiParent = event.currentTarget;

  if (!document.getElementById("none").checked) {
      setTimeout(() => {

  if (document.getElementById("tooltip").checked) {
      const target = emojiParent.getBoundingClientRect();
      const modal = document.getElementById("modal");
      modal.style.left = `${target.left + target.width / 2 - 150}px`;
      modal.style.top = `${target.top + target.height + 10}px`;
      modal.classList.add("tooltip");
    }

  else {
      const modal = document.getElementById("modal");
      modal.style.left = "0";
      modal.style.top = "0";
      modal.classList.add("popup");
      }

      const emotionText = emojiParent.querySelector(".text").innerText;
      document.getElementById("modalcontent").innerText = `Let your friends know why you feel ${emotionText}.`;

      setModalVisible(true);
      setTimeout(() => {
      const modal = document.getElementById("modal");
      modal.classList.add("animate");
      }, 100);
      }, 1000);
    }

    const countElement = emojiParent.querySelector(".count");
    const currentCount = parseInt(countElement.innerText, 10);
    countElement.innerText = currentCount + 1;
    countElement.classList.add("animate");

    setTimeout(() => {
      countElement.classList.remove("animate");
    }, 600);
    };

    React.useEffect(() => {
    const emojis = document.querySelectorAll(".emoji");
    emojis.forEach(emoji => {
      emoji.innerHTML = emojione.toImage(emoji.innerHTML);
      emoji.parentNode.addEventListener("click", handleReactionClick);
    });
    }, []);

return (
  <div id="container">
  <div className="content">
  <h1 class="text-gray-800 text-center text-2xl md:text-3xl mt-4 mb:2 md:mb-4">Your reaction?</h1>

  <div className="reaction">
  <div className="emoji">😃</div>
  <div className="count">0</div>
  <div className="text">happy</div>
  </div>

  <div className="reaction">
  <div className="emoji">😍</div>
  <div className="count">0</div>
  <div className="text">love</div>
  </div>

  <div class="reaction">
	<div class="emoji">😂</div>
	<div class="text">amused</div>
	<div class="count">0 </div>
  </div>

  <div className="reaction">
  <div className="emoji">😠</div>
  <div className="count">0</div>
  <div className="text">angry</div>
  </div>

  <div className="reaction">
  <div className="emoji">😢</div>
  <div className="count">0</div>
  <div className="text">sad</div>
  </div>

  <div className="settings" style={{ display: "none" }}>
  <strong>Share buttons:</strong>
  <input type="radio" name="settings" value="none" id="none" defaultChecked/>
  <label htmlFor="none">None</label>
  </div>
  </div>
  </div>
  
  );
  };

export default Starx;
