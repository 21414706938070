import React from 'react';
import './App.css';
import { BrowserRouter,Route,Switch} from 'react-router-dom';
import CreateArticle from './components/CreateArticle';
import Blog from'./containers/Blog';
import Contact from'./containers/Contact';
import About from'./containers/About';
import Footer from'./containers/Footer';
import Privacy from'./containers/Privacy';
import Terms from'./containers/Terms';
import Blogi from'./containers/Blogi';
import Homi from'./containers/Homi';
import HomeA from './containers/HomeA';
import Disclaimer from './containers/Disclaimer';
import Poet from './containers/Poet';
import Header from './components/Header';
import Marketing from './containers/Marketing';
import Blogging from './containers/Blogging';
import Businesa from './containers/Businesa';
import Education from './containers/Education';
import Journalism from './containers/Journalism';
import Technology from './containers/Technolgy';
import Seo from './containers/Seo';


const App = () => {
     
return (
  <BrowserRouter>
  <Switch>
  <div className='App' >
  <Header/>
  
        <Route exact path="/" component ={HomeA}/>
        <Route exact path="/about/"  component ={About}/>
        <Route exact path="/blogs/"  component ={Blog}/>
        <Route exact path="/contact/" component ={Contact}/>
        <Route exact path="/terms-of-service/"  component ={Terms}/>
        <Route exact path="/disclaimer/"  component ={Disclaimer}/>
        <Route exact path="/blogs/"  component ={Homi}/>
        <Route exact path="/blog/:id"  component ={Blogi}/>
        <Route exact path="/blogs/category/blogging/" component ={Blogging}/>
        <Route exact path="/blogs/category/business/" component ={Businesa}/>
        <Route exact path="/blogs/category/education/" component ={Education}/>
        <Route exact path="/blogs/category/journalism/" component ={Journalism}/>
        <Route exact path="/blogs/category/technology/" component ={Technology}/>
        <Route exact path="/blogs/category/marketing/" component ={Marketing}/>
        <Route exact path="/blogs/category/search-engine-optimisation/" component ={Seo}/>
        <Route exact path="/privacy-policy/"  component ={Privacy}/>
        <Route exact path="/author/technoankit/"  component ={Poet}/>

  <Footer/>
  </div>
  </Switch>
  </BrowserRouter>

  );
  } 

  export default App;