import React from 'react';
import { Card, CardHeader, CardBody, CardFooter, Typography, Button } from "@material-tailwind/react";
import Avatar from '@mui/material/Avatar';
import { useHistory } from 'react-router-dom';


const Author = () => {
  const history = useHistory();

  const handleIconButtonClick = pageUrl => {
    history.push(pageUrl)
   }   

return(
  <>
      
  <Card   className="w-full max-w-[53rem] ">
  <CardHeader color="transparent" floated={false} shadow={false} className="mx-0 flex items-center gap-2 pt-0" >
  <div className="flex w-full flex-col gap-0.5">
  <Avatar alt="ankit shukla" src="/images/shukla-2.webp" sx={{ width: 100, height: 100, ml:3 ,mb:1}} />

  <Typography  color="blue-gray" className='text-left text-lg md:text-xl px-4'><strong>Ankit Shukla</strong></Typography>
  
  <Typography color="blue-gray" className='text-left text-md md:text-lg px-4'>Full Stack Developer</Typography>

  </div>
        
 
  </CardHeader>
      
  <CardBody>
  <div className="mb-3 flex items-center justify-between">
  </div>
  <Typography color="gray" className='text-left text-lg md:text-xl'> Hello I am Ankit Shukla A  developer, passionate of writing blog posts on most popular niches like Content Writing Tools Using Artificial Intelligence. Working as the web developer is my passion as i love to work on exciting projects. Hope the above article is helpful for you. Do not skip other posts. Good Luck!</Typography>
  </CardBody>

  <CardFooter className="pt-3">
  <a href="/author/technoankit/">
  <Button ripple={false}  className="bg-orange-500 text-blue-gray-900 shadow-none hover:scale-105 hover:shadow-none focus:scale-105 focus:shadow-none active:scale-100">
  <Typography color="white" className="font-large"> View All Articles </Typography>
  </Button>
  </a>

  </CardFooter>
  </Card>
    
  </>
  
  )
  }

export default Author;