import React, {useState} from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import BlogItem from './BlogItem';
import { blogList } from './Data';
import Grid from '@mui/material/Grid';


const JInfluencers = [33];

const Businesa =({cover}) =>{
  const [visible, setVisible] = useState(6); 
 
return(

  <>
  <Grid item container >
  <Grid item xs={false} sm={false} md={1} lg={1} xl={1.5}/>
  <Grid item xs={12} sm={12} md={10} lg={10} xl={9}>
  <Navbar className='mb-20 md:mb-24 lg:mb-32 w-full bg-[#eab308] h-28 md:h-36 lg:h-44'>
  <h2 className='text-white text-left text-3xl md:text-5xl mt-10 md:mt-17 lg:mt-20 p-2'>Business</h2> 
  </Navbar>
  <h2 className='mb-8 md:mb-16 lg:mb-20 mr-4 hover:underline md:mr-6 text-lg'>showing 6 out 6 blogs</h2>
  <div className="grid gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mb-10 md:mb-20 lg:mb-28 p-1">
  
  { blogList.filter(blog => JInfluencers.includes(blog.host_id)).reverse().slice(0,visible).map(blog => {
   return <BlogItem blog={blog} key ={blog.id}/>;
   })}
  </div>
  </Grid>
  < Grid item xs={false} sm={false} md={1} lg={2} xl={2} />
  </Grid>
  </>
    
  )
  }

  export default Businesa;