import React from 'react';
import Grid from '@mui/material/Grid';

const Blocks = () => {
    
return (

  <section>
  <Grid item container >
  <Grid item xs={false} sm={false} md={1} lg={2} xl={2}/>
  <Grid item xs={12} sm={12} md={10} lg={8} xl={8}>
  
  <h3 className='text-gray-800 text-center text-2xl md:text-3xl lg:text-4xl p-2 mt-16 md:mt-20 lg:mt-24 hover:underline'><strong>Functionality To Support Any Use Case</strong></h3>

  
  <div className="grid gap-12 grid-cols-1 md:grid-cols-2  p-2 mt-8 md:mt-12 md:mt-12" data-aos-id-blocks>
  <div className="relative flex flex-col items-left" data-aos="fade-up" data-aos-anchor="[data-aos-id-blocks]">
  <svg className="w-12 h-12 md:w-16 md:h-16 mb-4" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
  <rect className="fill-current text-purple-600" width="64" height="64" rx="32" />
  <path className="stroke-current text-purple-100" d="M30 39.313l-4.18 2.197L27 34.628l-5-4.874 6.91-1.004L32 22.49l3.09 6.26L42 29.754l-3 2.924" strokeLinecap="square" strokeWidth="2" fill="none" fillRule="evenodd" />
  <path className="stroke-current text-purple-300" d="M43 42h-9M43 37h-9" strokeLinecap="square" strokeWidth="2" />
  </svg>
  <h1 className="h4 mb-1 text-left" >Complete Content Creation</h1><br/>
  <p className="text-left text-lg md:text-xl text-gray-600" >Content Writter Tools will compose a well-organized piece with logical sections and subsections from beginning to end.</p>
  </div>
  

  <div className="relative flex flex-col items-left" data-aos="fade-up" data-aos-delay="400" data-aos-anchor="[data-aos-id-blocks]">
  <svg className="w-12 h-12 md:w-16 md:h-16 mb-4" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
  <rect className="fill-current text-purple-600" width="64" height="64" rx="32" />
  <g transform="translate(21 21)" strokeLinecap="square" strokeWidth="2" fill="none" fillRule="evenodd">
  <ellipse className="stroke-current text-purple-300" cx="11" cy="11" rx="5.5" ry="11" />
  <path className="stroke-current text-purple-100" d="M11 0v22M0 11h22" />
  <circle className="stroke-current text-purple-100" cx="11" cy="11" r="11" />
  </g>
  </svg>
  <h1 className="h4 mb-1 text-left" >Write About any Breaking Topics</h1><br/>
  <p className="text-left text-lg md:text-xl text-gray-600">You may write accurate and pertinent Content on current events since Content writting tools does research in real-time.</p>
  </div>


  <div className="relative flex flex-col items-left" data-aos="fade-up" data-aos-delay="100" data-aos-anchor="[data-aos-id-blocks]">
  <svg className="w-12 h-12 md:w-16 md:h-16 mb-4" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
  <circle className="fill-current text-purple-600" cx="32" cy="32" r="32" />
  <path className="stroke-current text-purple-100" strokeWidth="2" strokeLinecap="square" d="M21 23h22v18H21z" fill="none" fillRule="evenodd" />
  <path className="stroke-current text-purple-300" d="M26 28h12M26 32h12M26 36h5" strokeWidth="2" strokeLinecap="square" />
  </svg>
  <h1 className="h4 mb-1 text-left" >Give instructions to Content Writter Tool.</h1><br/>
  <p className="text-left text-lg md:text-xl text-gray-600"> Give Content Writter precise instructions on what to write about so that it may produce Content that are more targeted, pertinent, and helpful.</p>
  </div>

  
  <div className="relative flex flex-col items-left" data-aos="fade-up" data-aos-delay="200" data-aos-anchor="[data-aos-id-blocks]">
  <svg className="w-12 h-12 md:w-16 md:h-16 mb-4" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
  <rect className="fill-current text-purple-600" width="64" height="64" rx="32" />
  <g transform="translate(21 21)" strokeLinecap="square" strokeWidth="2" fill="none" fillRule="evenodd">
  <ellipse className="stroke-current text-purple-300" cx="11" cy="11" rx="5.5" ry="11" />
  <path className="stroke-current text-purple-100" d="M11 0v22M0 11h22" />
  <circle className="stroke-current text-purple-100" cx="11" cy="11" r="11" />
  </g>
  </svg>
  <h1 className="h4 mb-1 text-left" >Expand And Enhance Content</h1><br/>
  <p className="text-left text-lg md:text-xl text-gray-600">Using Content Writter , you can extend your content up to 2000 words or more, adding as many headers, sub-headers, and sections as you like.</p>
  </div>
  </div>

  </Grid>
  < Grid item xs={false} sm={false} md={1} lg={2} xl={2}/>
  </Grid> 
  </section>

  )
  }

export default Blocks;