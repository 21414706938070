import React from 'react';
import Grid from '@mui/material/Grid';

const Blog = () => {
  
return(
  <>

  <Grid item container >
  <Grid item xs={false} sm={false} md={2}/>

  <Grid item xs={12} sm={12} md={8}>
  </Grid>

  <Grid item xs={false} sm={false} md={2}/>
  </Grid>

  </>

  )
  }

export default Blog;












