import React from 'react';
import { Link , useHistory} from "react-router-dom";


const Footer = () => {
  const history = useHistory();
  
  const handleIconButtonClick = pageUrl => {
        history.push(pageUrl)
  }   
  
  
 return(
    <>
    <footer className="position-relative h-[100px] lg:h-[80px] inline-block p-4 bg-white border-t border-gray-200 shadow md:flex md:items-center  md:justify-between md:p-6 dark:bg-gray-800 dark:border-gray-600">
    
    <ul className= "flex flex-wrap items-center justify-center mt-0 text-sm font-medium text-gray-500 dark:text-gray-400 sm:mt-0 ml-2 flex space-x-3 ...">
    <li1><Link onClick={() => handleIconButtonClick ("/terms-of-service/")}  className="mr-4 hover:underline md:mr-6 ">Terms of Service</Link></li1>
    <li1><Link onClick={() => handleIconButtonClick ("/disclaimer/")}  className="mr-4 hover:underline md:mr-6 ">Disclaimer </Link></li1>
    <li1><Link onClick={() => handleIconButtonClick ("/privacy-policy/")}   className="mr-4 hover:underline md:mr-6">Privacy Policy</Link></li1>
    <li1><Link onClick={() => handleIconButtonClick ("/contact/")}   className="mr-4 hover:underline md:mr-6">Contact</Link></li1>
    </ul>
    
    <span className="text-sm text-gray-700 sm:text-center md:text-center inline-block">Copyright © 2024&nbsp;<a href="https://nulfye.com/" class="hover:underline">Nulfye</a></span>
    </footer>
    </>
  );
  }   

export default Footer;