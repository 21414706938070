import React, {useState,useEffect} from 'react';
import Form from 'react-bootstrap/Form';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import { css } from "@emotion/react";
import Button from '@mui/material/Button';
import { useDispatch } from 'react-redux';
import { createPost } from '../actions/posts';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { styled} from '@mui/system';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Header from './Header';
import './aiar.css';
import { useHistory } from 'react-router-dom';
import { useRef } from 'react';
import Grid from '@mui/material/Grid';
import { deepPurple } from '@mui/material/colors';
import Scroll from '../containers/Scroll';
import { useSelector } from 'react-redux';
import "bootstrap/dist/css/bootstrap.min.css";
import './summary.css';
import write from './write.gif';
import './aiarticles.css';
import ImageAd from './ImageAd';
import MyEditor from './Edi';
import { IoMdClose } from "react-icons/io";
import { useParams } from "react-router-dom";
import MicIcon from "./mic.svg";
import { HiPencil } from "react-icons/hi2";

const customTheme = createTheme({
  palette: {
    primary: {
      main: deepPurple[50],
    },
  },
});

const StyledTextField = styled(TextField)`
  ${({ theme }) => `
  cursor: pointer;
  background-color: ${theme.palette.primary.main};
  transition: ${theme.transitions.create(['background-color', 'transform'], {
    duration: theme.transitions.duration.standard,
  })};
  &:hover {
    background-color: ${theme.palette.secondary.main};
    transform: scale(1.1);
  }
  `}
`;


const BackdropUnstyled = React.forwardRef((props, ref) => {
  const { open, className, ...other } = props;
  return (
    <div
      className={clsx({ 'MuiBackdrop-open': open }, className)}
      ref={ref}
      {...other}
    />
  );
});

BackdropUnstyled.propTypes = {
  className: PropTypes.string.isRequired,
  open: PropTypes.bool,
};

const Backdrop = styled(BackdropUnstyled)`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = (theme) => ({
  width: 400,
  bgcolor: theme.palette.mode === 'dark' ? '#0A1929' : 'white',
  border: '2px solid currentColor',
  padding: '16px 32px 24px 32px',
});

const icon = (
  <Paper sx={{ m: 1  }} elevation={4}>
    <Box component="svg" sx={{ width: 100, height: 100 }}>
      <Box
        sx={{
          fill: (theme) => theme.palette.common.white,
          stroke: (theme) => theme.palette.divider,
          strokeWidth: 1,
        }}
        points="0,100 50,00, 100,100"
      />
    </Box>
  </Paper>
);
  
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const { Configuration, OpenAIApi } = require("openai");

const AiArticles = ()=> {
  const { post, posts, isLoading } = useSelector((state) => state.posts);
  const [progress, setProgress] = React.useState(0);
  const [percent, setPercent] = useState(0);
  const btnRef = useRef(null);
  const btnnRef = useRef(null);
  const ref = useRef(null);
  const [results, setResults] = useState([]);
  const history = useHistory();
  const [ postData , setPostData] = useState({heading:'', response :[]});
  const [keywords , setKeywords] = useState({ title: '' , body: '' });
  const [article, setArticle] = useState([]);
  const [timer, setTimer] = useState(0);
  const [skills, setSkills] = useState([]);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [showing, setShowing] = useState(false);
  const user = JSON.parse(localStorage.getItem('profile'));
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { query } = useParams();
  const [searchQuery, setSearchQuery] = useState(query || "");

  const handleIconButtonClick = pageUrl => {
  history.push(pageUrl)
  }    

  const handleClick = () => {
  ref.current?.scrollIntoView({ behavior: 'smooth' });
  };


  const searchQueryHandler = (event) => {
    if (event?.key === "Enter" && searchQuery?.length > 0) {
        history(`/${searchQuery}/${1}`);
    }
   };
      
  const onFormSubmit =  e => {
  e.preventDefault();
  dispatch(createPost({ ...postData}));
  const formData = new FormData(e.target),
  formDataObj = Object.fromEntries(formData.entries());
  e.target.reset();

  const configuration  = new Configuration({
  apiKey: process.env.OPENAI_API_KEY,               
  });
              
  const openai = new OpenAIApi(configuration);

  openai.createCompletion({
  model: "gpt-3.5-turbo-instruct",
  prompt: `Generate top 4 keywords title related to the topic in to a professional  manner ,using the title provided.\nTitle: {}\n*.${formDataObj.articleTopic}`,
  temperature:  0.7, 
  max_tokens: 150,
  top_p: 1,
  frequency_penalty: 0,
  presence_penalty: 0,

  })

  .then ((response ) => {
  setPostData({
  heading : `${formDataObj.articleTopic}`,
  response: response.data.choices[0].text.split('\n').slice(2),
  });
  });
  };


  const onFormSend =  e => {
  e.preventDefault();
  dispatch(createPost({ ...keywords,article }));


  const formData = new FormData(e.target),
  formDataObj = Object.fromEntries(formData.entries());
  e.target.reset();

  const configuration  = new Configuration({
  apiKey: process.env.OPENAI_API_KEY,               
  });
              
  const openai = new OpenAIApi(configuration); 
  openai.createCompletion({
  model: "gpt-3.5-turbo-instruct",
  prompt: `generate defination on a given topic.\nTitle: {}\n.${formDataObj.articleSectionHeading}`,
  temperature:  0.7, 
  max_tokens:  350,
  top_p: 1,
  frequency_penalty: 0,
  presence_penalty: 0,

  })

  .then ((response ) => {
  setKeywords({
  title : `${formDataObj.articleSectionHeading}`,
  body :`${response.data.choices[0].text}`,

  });
  });

  let movies = [];
  for(let i=0; i<skills.length; i++){
  movies.push(

  openai.createCompletion({
  model: "gpt-3.5-turbo-instruct",
  prompt: `Generate detailed article section write up for the following article section heading, using the title provided.\nTitle: {}\nArticle Section Heading:{}\n.${skills[i]}`,
  temperature:  0.9, 
  max_tokens: 350,
  top_p: 0.9,
  frequency_penalty: 1,
  presence_penalty: 0,
  
  })
  .then ((response ) => {
  if(response.data && response.data.choices && response.data.choices.length > 0){
  Promise.all(movies)
  setArticle(article=>[article,response.data.choices[0].text])
  }})
  )} 
  }
     
  function checkboxHandler(e){
  let isSelected = e.target.checked;
  let value = parseInt(e.target.value);
  
  if( isSelected ){
  setSkills([...skills, value])
  }else{
  setSkills((prevData)=>{
  return prevData.filter((id)=>{
  return id!==value
  })
  })
  }
  }

  function checkAllHandler(){
	if( postData.response.length === skills.length){
  setSkills([])
  }else{
  const postIds = postData.response.map((item)=>{
  return item
  })
  setSkills(postIds)
  }
  }

  useEffect(() => {
  if (timer) {
  clearTimeout(timer);
  }
  setTimer(
  setTimeout(() => {
  btnRef.current.click();
  }, 17000)); 
  }, [])


  useEffect(() => {
  if (timer) {
  clearTimeout(timer); 
  }
  setTimer(
  setTimeout(() => {
  btnnRef.current.click();
  }, 20000)); 
  },[]); 

  
  useEffect(() => {
  if (loading) {
  setTimeout(() => {
  setLoading(false);
  }, 20000);
  }
  }, [loading]);
  
  
  const helloHandeler = () => {
  setLoading(!loading);
  setTimeout(() => {
  setLoading(!loading);
  setShowing(!showing);
  }, 20000);
  };

  React.useEffect(() => {
  const timer = setInterval(() => {
  setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
  }, 18000);
  return () => {
  clearInterval(timer);
  };
  }, []);
 
  

  return ( 
    <div >
    <div style={{height:"60vh"}}>
    <div className="head_text" align="center" ><br/>
    <span className='spa'>Intelligence</span> <span className='spb '>That</span> <span className='spc '>Writes</span> <span className='spd '>Articles</span><br className='max-md:hidden'/>
     &nbsp;
    <span className='blue_gradient '>Effortlessly</span>
    </div>
    <div  align="center" class="heado_text" >
    <span className='spa'>N</span>&nbsp;<span className='spb'>u</span>&nbsp;<span className='spc'>l</span>&nbsp;<span className='spd'>F</span> <span className='spe'>y</span>  <span className='spc'>e</span>    <span className='blue_gradient '>.com</span>
    </div>
     
    <Form   className ="beauty" onSubmit={onFormSubmit} align ="center">
    <Form.Group  className="mb-3" controlId='formBasicEmail'>
    <Form.Label>  <Typography>
    </Typography> </Form.Label>
    <br/>

   {/* <ThemeProvider theme={customTheme}>

    <TextField   type ="text" name="articleTopic" variant="outlined" placeholder= "Enter Your Title Here" align="center" 
     required='true'  value={postData.heading} onChange={(e) => setPostData({ ...postData, heading: e.target.value })}   sx={{'& > :not(style)': { m: 1, maxWidth: '65ch' ,width: 'flex' ,borderRadius: 8 ,textAlign:"right"},display:{xs:'flex'} }}/> 

    </ThemeProvider>*/}

           <div 
           id="searchBox" 
           className="h-[50px] w-full md:w-[584px] flex items-center gap-3 px-4 border border-[#dfe1e5] rounded-3xl hover:bg-white hover:shadow-c hover:border-0 focus-within:shadow-c focus-within:border-0">
            <HiPencil size={18} color="#9aa0a6"/>
            <input
                type="text"
                name="articleTopic"
                onChange={(e) => setPostData({ ...postData, heading: e.target.value })}
                value={postData.heading}

                className="grow outline-0 text-black/[0.87]"
                placeholder= "Enter Your Title Here"
                onKeyUp={searchQueryHandler}
autofocus
              
            />
            <div className="flex items-center gap-3">
                {searchQuery && (
                    <IoMdClose
                        size={24}
                        color="#70757a"
                        className="cursor-pointer"
                        onClick={() => setSearchQuery("")}
                    />
                )}
            <img className="h-6 w-6 cursor-pointer" src={MicIcon} alt=""/>
                
            </div>
        </div>

    </Form.Group>

    <Button   variant='contained' size="medium"  type="submit" color="inherit"  disabled={!postData.heading}  onClick={()=>{handleOpen();setShow(!show);helloHandeler();}} onKeyDown={e => e.key === 'Enter' ? onFormSubmit: ''} >
    <Typography  color="black" >Create Article</Typography>
    </Button>  
 
    </Form>

    <Form   className ="beauty" onSubmit={onFormSend} align ="center">
    <Form.Group  className="mb-3" controlId='formBasicEmail'>
    <Form.Label>  <Typography>
    </Typography> </Form.Label>

    <Grid item container >
    <Grid item xs={false} sm={false} md={2}/>
    <Grid item xs={12} sm={12} md={8} >

    <Paper  variant="outlined" sx={{display:{xs:'none'}}}>
    <TextField name="articleSectionHeading" variant="standard" value={postData.heading}  />

    { 
    postData?.response?.map((c,i) =>{
    return(
    <tr key={i} >
    <td>{c}</td>
    <td>
    <input
    type="checkbox"
    name="articleSubTopic"
    value={c}
    checked={skills} 
    onChange={checkboxHandler}
    />
    </td> 
    </tr>) 
    })}
    </Paper>

    </Grid>
    <Grid item xs={false} sm={false} md={2} />
    </Grid>
    </Form.Group>
    <Button type="submit" ref={btnRef} onClick={checkAllHandler} sx={{display:{xs:'none'}}} disabled={!postData.heading}> <Typography>Create Article</Typography> </Button>
    <Button variant='contained' size="medium"  type="submit"  color="inherit" ref={btnnRef} disabled={!postData.heading}  onClick = {handleClick} sx={{display:{xs:'none'}}}   >
   
    <Typography>Create Article</Typography> </Button>
    </Form>
    </div>

  {/* Show Article */}
  <Grid item container >
  <Grid item xs={false} sm={false} md={1} lg={1} xl={2}/>
  <Grid item xs={12} sm={12} md={10} lg={10} xl={8}>
 
  { loading ? 
   
    <div class="overlap">
     <div class="overlap__inner">
     <div class="overlap__content">  
     <img src={write} height={1110} alt="Loading ..."/>
      Writing...
     </div>
     </div>
     </div>

  : show&& 

  <div className='summary_box' style={{height:"auto"}} >

  <h1 class='ciby' align='center'   >{postData?.heading.toLocaleUpperCase()}</h1>
  

  <Divider color="black" sx={{mt:3}}/>
  <Typography  sx={{ mt:5}} align='left'>{keywords.body}</Typography>
  <ImageAd/>

  { article.map((d,i) => {
  return(
  <div key={i} ><br/>
  <Typography align='left'>{d}</Typography>
  </div>
  )})}
  </div>   
  } 
  </Grid>
  <Grid item xs={false} sm={false} md={1} lg={1} xl={2}/>
  </Grid>
  <div style={{ height: '2rem' }} />
  <div ref={ref}></div>
  <Scroll/>
 
  </div>
  );
  } 

export default AiArticles;