import React, { useRef ,useState } from "react";
import emailjs from "@emailjs/browser";
import Swal from 'sweetalert2'

const Contact = () => {
  const form = useRef();
  
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
    .sendForm( "service_m9hyu5p", "template_z5tl5wq", form.current, "FB96w7y0h9sXI-dHg" )
    .then(
    (result) => {
    console.log(result.text);
    console.log("message sent");
    },
    (error) => {
    console.log(error.text);
    }
    );
  };

const fireAlert = () => {
  Swal.fire({
    title: "Message Sent Successfully!",
    icon: "success"
  });
}

return (
  
  <div>
  <div class="bg-[#bae6fd]">
  </div>
  <div class="grid lg:grid-cols-3 items-center max-lg:justify-center h-full py-6 px-16 max-sm:px-4 bg-orange-100 font-[sans-serif]">
  <div class="max-w-lg max-lg:mx-auto max-lg:text-center max-lg:mb-6  " >
  <h2 class="text-4xl font-extrabold text-[#e29328]">Get In Touch</h2>
  <p class="text-md text-[black] mt-4" align="left">We value our customers and their feedback. Contact us with any suggestions, concerns, or even just to say hello. We love hearing from you and are always looking to improve our services.
  </p>

  <form class="mx-auto mt-8 bg-white rounded-lg py-6 px-4 shadow-md " ref={form} onSubmit={sendEmail}>
  <input type='text' placeholder='Name' name="user_name" class="w-full rounded-md h-12 px-6 bg-[#f0f1f2] text-sm mb-4 outline-none" />
  <input type='email' placeholder='Email' name="user_email" class="w-full rounded-md h-12 px-6 bg-[#f0f1f2] text-sm mb-4 outline-none" />
  <input type='text' placeholder='Mobile no' name="user_mobile" class="w-full rounded-md h-12 px-6 bg-[#f0f1f2] text-sm mb-4 outline-none" />
  <textarea placeholder='Message' rows="6" name="message" class="w-full rounded-md px-6 bg-[#f0f1f2] text-sm pt-3 outline-none"></textarea>
  <button type='submit' value="send" class="text-[#333] bg-orange-300 hover:bg-orange-500 font-semibold rounded-md text-sm px-6 py-3 block w-full mt-3"  onClick={fireAlert}>Send Message</button>

  </form>
  </div>

  <div class="z-0 relative lg:col-span-2">
  <img src='/conpic/ioa.webp' alt="" loading="lazy" class="w-full h-full" />
  </div>
  </div>
  </div>
  );
  };

export default Contact;

