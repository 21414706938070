import React, { useEffect, useState } from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import EmptyList from './EmptyList';
import ContactC from './ContactC';
import { blogList } from './Data';
import './blogi.css';
import Ico from './Ico';
import Author from './Author';
import Emoji  from './Emoji';
import BlogsB from './BlogsB';
import Grid from '@mui/material/Grid';


const Headings = ({ headings, activeId }) => (

  <ul>

  { headings.map((heading) => (
  <li key={heading.id} className={heading.id === activeId ? "active" : ""}>
  <a href={`#${heading.id}`} onClick={(e) => { e.preventDefault(); document.querySelector(`#${heading.id}`).scrollIntoView({ behavior: "smooth" });
  }}>
  <div className='mb-4'>{heading.title}</div></a>

  { heading.items.length > 0 && (
  <ul>

  { heading.items.map((child) => (
  <li key={child.id} className={child.id === activeId ? "active" : ""} >
  <a href={`#${child.id}`} onClick={(e) => { e.preventDefault();  document.querySelector(`#${child.id}`).scrollIntoView({ behavior: "smooth"});
  }}>
  <div className='mb-4'>{child.title} </div></a>
  </li>

  ))}

  </ul>
  )}
  </li>
  ))}
  </ul>
);


const useHeadingsData = () => {
  const [nestedHeadings, setNestedHeadings] = React.useState([]);

  React.useEffect(() => {
  const headingElements = Array.from(
  document.querySelectorAll("main h2, main h3")
  );

  const newNestedHeadings = getNestedHeadings(headingElements);
  setNestedHeadings(newNestedHeadings);
  },[]);

  return { nestedHeadings };

};

const getNestedHeadings = (headingElements) => {
  const nestedHeadings = [];

  headingElements.forEach((heading, index) => {
  const { innerText: title, id } = heading;

  if (heading.nodeName === "H2") {
  nestedHeadings.push({ id, title, items: [] });
  } else if (heading.nodeName === "H3" && nestedHeadings.length > 0) {
  nestedHeadings[nestedHeadings.length - 1].items.push({ id, title });
  }
  });
  return nestedHeadings;
};

const useIntersectionObserver = (setActiveId) => {
  const headingElementsRef = React.useRef({});

  React.useEffect(() => {
  const callback = (headings) => {
  headingElementsRef.current = headings.reduce((map, headingElement) => { map[headingElement.target.id] = headingElement;
  return map;
  }, headingElementsRef.current);


  const visibleHeadings = [];
  Object.keys(headingElementsRef.current).forEach((key) => {
  const headingElement = headingElementsRef.current[key];
  if (headingElement.isIntersecting) visibleHeadings.push(headingElement);
  });

  const getIndexFromId = (id) =>
  headingElements.findIndex((heading) => heading.id === id);

  if (visibleHeadings.length === 1) {
  setActiveId(visibleHeadings[0].target.id);
  } else if (visibleHeadings.length > 1) {
  const sortedVisibleHeadings = visibleHeadings.sort(
  (a, b) => getIndexFromId(a.target.id) > getIndexFromId(b.target.id)
  );
  setActiveId(sortedVisibleHeadings[0].target.id);
  }
  };

  const observer = new IntersectionObserver(callback, { root: document.querySelector("iframe"), rootMargin: "500px" });
  const headingElements = Array.from(document.querySelectorAll("h2, h3"));
  headingElements.forEach((element) => observer.observe(element));
  return () => observer.disconnect();
  }, [setActiveId]);

};

const TableOfContents = () => {
  const [activeId, setActiveId] = React.useState();
  const { nestedHeadings } = useHeadingsData();
  useIntersectionObserver(setActiveId);

  return (

  <nav aria-label="Table of contents">
  <h1 className='mb-8 hover:underline titleMainText mt-4 text-2xl'>Table Of Contents</h1>

  <Headings headings={nestedHeadings} activeId={activeId} />
  </nav>
  );
};

const Blogi = () => {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);
  const history = useHistory();

  useEffect(() => {
    let blog = blogList.find((blog) => blog.id === (id));
    if (blog) {
    setBlog(blog);
    }
  }, []);

  const handleIconButtonClick = pageUrl => {
   history.push(pageUrl)
  }   

  return(

  <>
  <main className='p-3'>
  <Grid item container >
  <Grid item xs={false} sm={false} md={1} lg={1} xl={1.5}/>
  <Grid item xs={12} sm={12} md={10} lg={10} xl={9}>

  {blog ? (
  <div className='blog-wrap'>
  <section className="pb-[0px] pt-[40px] md:pt-[70px] lg:pt-[85px]">

  <div className="-mx-4 flex flex-wrap justify-center">
  <div align="left" class="hidden lg:block">
  <TableOfContents/> 
  </div>

  <div className="w-full px-0 lg: px-2 lg:w-8/12">   
  <div>
  <div className='mb-2 flex flex-wrap'>
  <Link onClick={() => handleIconButtonClick ("/")} className='text-left text-md leading-relaxed text-gray-700'> Home </Link>&nbsp;<MdOutlineKeyboardDoubleArrowRight class="mt-1"/>&nbsp;<Link onClick={() => handleIconButtonClick ("/blogs/")} className='text-left text-md leading-relaxed text-gray-700'> Blog</Link>&nbsp;<MdOutlineKeyboardDoubleArrowRight class="mt-1"/>&nbsp;<Link onClick={() => handleIconButtonClick (`/blogs/category/${blog.category.toLowerCase()}`)} className='text-left text-md leading-relaxed text-gray-700'>{blog.category}</Link>&nbsp;<MdOutlineKeyboardDoubleArrowRight class="mt-1"/>&nbsp;<p className='text-left'>{blog.title}</p>
  </div>
  <h2 className= "mb-3 text-3xl font-bold leading-tight text-black dark:text-white md:text-4xl md:leading-tight" align="left" id="initial-header">
  {blog.title}
  </h2>


  <div className="mb-10 flex flex-wrap items-center justify-between border-b border-body-color border-opacity-10  dark:border-white dark:border-opacity-10">
  <div className="flex flex-wrap items-center mb-2">
  <div className="mb-2 md:mb-5 mr-10 flex items-center">
  <div className="mr-4">
  <div className="relative h-8 w-8 overflow-hidden rounded-full">
  <img src="/images/ankit.webp" alt="author" fill />
  </div>
  </div>
  <div className="w-full">

  <span className="mb-0 md:mb-1 text-md md:text-md text-body-color">
  <a href="/author/technoankit/">
  By <span>Ankit Shukla</span>  </a>

  </span>
  </div>
  </div>
  <div className="mb-2 flex items-center">
  <p className="mr-5 flex items-center text-md text-body-color inline-block">

  <span className="mr-3">
  <svg width="20" height="20" viewBox="0 0 15 15" className="fill-current">
  <path d="M3.89531 8.67529H3.10666C2.96327 8.67529 2.86768 8.77089 2.86768 8.91428V9.67904C2.86768 9.82243 2.96327 9.91802 3.10666 9.91802H3.89531C4.03871 9.91802 4.1343 9.82243 4.1343 9.67904V8.91428C4.1343 8.77089 4.03871 8.67529 3.89531 8.67529Z" />
  <path d="M6.429 8.67529H5.64035C5.49696 8.67529 5.40137 8.77089 5.40137 8.91428V9.67904C5.40137 9.82243 5.49696 9.91802 5.64035 9.91802H6.429C6.57239 9.91802 6.66799 9.82243 6.66799 9.67904V8.91428C6.66799 8.77089 6.5485 8.67529 6.429 8.67529Z" />
  <path d="M8.93828 8.67529H8.14963C8.00624 8.67529 7.91064 8.77089 7.91064 8.91428V9.67904C7.91064 9.82243 8.00624 9.91802 8.14963 9.91802H8.93828C9.08167 9.91802 9.17727 9.82243 9.17727 9.67904V8.91428C9.17727 8.77089 9.08167 8.67529 8.93828 8.67529Z" />
  <path d="M11.4715 8.67529H10.6828C10.5394 8.67529 10.4438 8.77089 10.4438 8.91428V9.67904C10.4438 9.82243 10.5394 9.91802 10.6828 9.91802H11.4715C11.6149 9.91802 11.7105 9.82243 11.7105 9.67904V8.91428C11.7105 8.77089 11.591 8.67529 11.4715 8.67529Z" />
  <path d="M3.89531 11.1606H3.10666C2.96327 11.1606 2.86768 11.2562 2.86768 11.3996V12.1644C2.86768 12.3078 2.96327 12.4034 3.10666 12.4034H3.89531C4.03871 12.4034 4.1343 12.3078 4.1343 12.1644V11.3996C4.1343 11.2562 4.03871 11.1606 3.89531 11.1606Z" />
  <path d="M6.429 11.1606H5.64035C5.49696 11.1606 5.40137 11.2562 5.40137 11.3996V12.1644C5.40137 12.3078 5.49696 12.4034 5.64035 12.4034H6.429C6.57239 12.4034 6.66799 12.3078 6.66799 12.1644V11.3996C6.66799 11.2562 6.5485 11.1606 6.429 11.1606Z" />
  <path d="M8.93828 11.1606H8.14963C8.00624 11.1606 7.91064 11.2562 7.91064 11.3996V12.1644C7.91064 12.3078 8.00624 12.4034 8.14963 12.4034H8.93828C9.08167 12.4034 9.17727 12.3078 9.17727 12.1644V11.3996C9.17727 11.2562 9.08167 11.1606 8.93828 11.1606Z" />
  <path d="M11.4715 11.1606H10.6828C10.5394 11.1606 10.4438 11.2562 10.4438 11.3996V12.1644C10.4438 12.3078 10.5394 12.4034 10.6828 12.4034H11.4715C11.6149 12.4034 11.7105 12.3078 11.7105 12.1644V11.3996C11.7105 11.2562 11.591 11.1606 11.4715 11.1606Z" />
  <path d="M13.2637 3.3697H7.64754V2.58105C8.19721 2.43765 8.62738 1.91189 8.62738 1.31442C8.62738 0.597464 8.02992 0 7.28906 0C6.54821 0 5.95074 0.597464 5.95074 1.31442C5.95074 1.91189 6.35702 2.41376 6.93058 2.58105V3.3697H1.31442C0.597464 3.3697 0 3.96716 0 4.68412V13.2637C0 13.9807 0.597464 14.5781 1.31442 14.5781H13.2637C13.9807 14.5781 14.5781 13.9807 14.5781 13.2637V4.68412C14.5781 3.96716 13.9807 3.3697 13.2637 3.3697ZM6.6677 1.31442C6.6677 0.979841 6.93058 0.716957 7.28906 0.716957C7.62364 0.716957 7.91042 0.979841 7.91042 1.31442C7.91042 1.649 7.64754 1.91189 7.28906 1.91189C6.95448 1.91189 6.6677 1.6251 6.6677 1.31442ZM1.31442 4.08665H13.2637C13.5983 4.08665 13.8612 4.34954 13.8612 4.68412V6.45261H0.716957V4.68412C0.716957 4.34954 0.979841 4.08665 1.31442 4.08665ZM13.2637 13.8612H1.31442C0.979841 13.8612 0.716957 13.5983 0.716957 13.2637V7.16957H13.8612V13.2637C13.8612 13.5983 13.5983 13.8612 13.2637 13.8612Z" />
  </svg>
  </span>
   Updated on&nbsp;{blog.createdAt}
  </p>
                      
  <p className="flex items-center text-md md:text-lg  text-body-color ">
  <span className="mr-3 ">
  <svg width="20" height="12" viewBox="0 0 20 12" className="fill-current">
  <path d="M10.2559 3.8125C9.03711 3.8125 8.06836 4.8125 8.06836 6C8.06836 7.1875 9.06836 8.1875 10.2559 8.1875C11.4434 8.1875 12.4434 7.1875 12.4434 6C12.4434 4.8125 11.4746 3.8125 10.2559 3.8125ZM10.2559 7.09375C9.66211 7.09375 9.16211 6.59375 9.16211 6C9.16211 5.40625 9.66211 4.90625 10.2559 4.90625C10.8496 4.90625 11.3496 5.40625 11.3496 6C11.3496 6.59375 10.8496 7.09375 10.2559 7.09375Z" />
  <path d="M19.7559 5.625C17.6934 2.375 14.1309 0.4375 10.2559 0.4375C6.38086 0.4375 2.81836 2.375 0.755859 5.625C0.630859 5.84375 0.630859 6.125 0.755859 6.34375C2.81836 9.59375 6.38086 11.5312 10.2559 11.5312C14.1309 11.5312 17.6934 9.59375 19.7559 6.34375C19.9121 6.125 19.9121 5.84375 19.7559 5.625ZM10.2559 10.4375C6.84961 10.4375 3.69336 8.78125 1.81836 5.96875C3.69336 3.1875 6.84961 1.53125 10.2559 1.53125C13.6621 1.53125 16.8184 3.1875 18.6934 5.96875C16.8184 8.78125 13.6621 10.4375 10.2559 10.4375Z" />
  </svg>
  </span>
  {blog.read}
  </p>
  </div>
  
  </div>
  <div className="mb-2 ">
  <Link onClick={() => handleIconButtonClick (`/blogs/category/${blog.category.toLowerCase()}`)}className="inline-flex items-center justify-center rounded-full bg-[#475569] px-4 py-2 text-sm font-semibold text-white hidden lg:block">{blog.category}</Link>
  </div></div>

  <div>
  <p className="text-left text-lg md:text-xl mt-1 md:mt-2 leading-relaxed text-gray-800" align="left" dangerouslySetInnerHTML={{__html: blog.description}} ></p>
  <div className="mb-10 w-full overflow-hidden rounded mt-4">
  <div className="relative aspect-[97/60]  w-full sm:aspect-[97/44] md:aspect-[97/60]">
  <img src={blog.gipy} alt="image" fill className="object-cover object-left" />
  </div>
  </div>
                
  <h3 className="titleMainText mt-4 md:mt-8 mb-4 md:mb-12 text-2xl" align="left" id="second-header">
  {blog.swift} </h3>

  <p className="text-left text-lg md:text-xl mt-1 md:mt-2 leading-relaxed text-gray-800" align="left"  
  dangerouslySetInnerHTML={{__html: blog.paragraph}}></p>

  <div className="mb-10 w-full overflow-hidden rounded mt-4" >
  <div className="relative aspect-[97/60] w-full sm:aspect-[97/44]">
  <img src={blog.cipy} alt="image" fill className="object-cover object-center" />
  </div>
  </div>

  <h3 className="titleMainText mt-4 md:mt-8 mb-4 md:mb-12 text-2xl" align="left" id="third-header">
  {blog.ford}</h3>
  
  <p className="text-left text-lg md:text-xl mt-1 md:mt-2 leading-relaxed text-gray-800" align="left"  
  dangerouslySetInnerHTML={{__html: blog.details}}></p>

  <div className="mb-10 w-full overflow-hidden rounded mt-4">
  <div className="relative aspect-[97/60] w-full sm:aspect-[97/44]" >
  <img src={blog.dipy} alt="image" fill className="object-cover object-center"/>
  </div>
  </div>

  <h3 className="titleMainText mt-4 md:mt-8 mb-4 md:mb-12 text-2xl" align="left" id="fourth-header">
  {blog.chevy}</h3>
  <p className="text-left text-lg md:text-xl mt-1 md:mt-2 leading-relaxed text-gray-800" align="left"  
  dangerouslySetInnerHTML={{__html: blog.colum}}></p>

  <div className="mb-10 w-full overflow-hidden rounded mt-4">
  <div className="relative aspect-[97/60] w-full sm:aspect-[97/44]">
  <img src={blog.eipy} alt="image" fill className="object-cover object-center" />
  </div>
  </div>

  <h3 className="titleMainText mt-4 md:mt-8 mb-4 md:mb-12 text-2xl" align="left" id="fifth-header">
  {blog.lotus}</h3>
  <p className="text-left text-lg md:text-xl mt-1 md:mt-2 leading-relaxed text-gray-800" align="left"  
  dangerouslySetInnerHTML={{__html: blog.text}}></p>

  <div className="mb-10 w-full overflow-hidden rounded mt-4">
  <div className="relative aspect-[97/67] w-full sm:aspect-[97/44]">
  <img src={blog.fipy} alt="image" fill className="object-cover object-center" />
  </div>
  </div>

  <h3 className="titleMainText mt-4 md:mt-8 mb-4 md:mb-12 text-2xl" align="left" id="sixth-header" >
  {blog.crain}</h3>
  <p className="text-left text-lg md:text-xl mt-1 md:mt-2 leading-relaxed text-gray-800" align="left"  dangerouslySetInnerHTML={{__html: blog.lopi}}>
  </p>

  <h3 className="titleMainText mt-4 md:mt-8 mb-4 md:mb-12 text-2xl " align="left" id="seventh-header">
  {blog.hinge}</h3>
  <p className="text-left text-lg md:text-xl mt-1 md:mt-2 leading-relaxed text-gray-800 " align="left" dangerouslySetInnerHTML={{__html: blog.deep}}></p>
  
  <Ico/>

  <div class="mt-12">
  <hr/><hr/>
  <Emoji/>
  </div>
                  
  <div className="p-8 mb-4 bg-light bg-gradient text-white rounded-2" align="left">

  <div class="nav-buttons-container">
  <a href="/blogs/" class="previous-button">
  <div class="icon">
  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
  <path stroke-linecap="round" stroke-linejoin="round" d="M11 19l-7-7 7-7m8 14l-7-7 7-7" />
  </svg>
  </div>

  <div class="label">Back</div>
  </a>


  </div>
  </div>

  <Author/>  
  <BlogsB/>
  </div>
  </div>


  </div>

  <ContactC/>

  </div>
  </section>
  </div>

  ):(
  <EmptyList/>
  )}
  </Grid>
  < Grid item xs={false} sm={false} md={1} lg={1} xl={1.5} />
  </Grid>
  </main>

  </>
  );
  }
export default Blogi;