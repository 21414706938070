import React,{useState} from 'react';
import  { blogList }   from './Data';
import BlogItem from './BlogItem';
import {  useLocation } from 'react-router-dom';
import { Link } from "react-router-dom";
import { Card, CardHeader, CardBody, Typography ,Avatar } from "@material-tailwind/react";
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';


const Poet =() =>{
  const [blogs, setBlogs] = useState(blogList);
  const [visible, setVisible] = useState(6); 

  
  const handlerLoadMore = () => {
    setVisible((prevValue) => prevValue + 6);
  };

return(
  <>
  <Grid item container >
  <Grid item xs={false} sm={false} md={1} lg={1} xl={1.5}/>
  <Grid item xs={12} sm={12} md={10} lg={10} xl={9}>
  <Paper  variant="outlined" align="center" className='bg-transparent mb-12 ' >
  <Card color="transparent" shadow={false} className="w-full max-w-[40rem]" >
  <CardHeader color="transparent" floated={false} shadow={false} className="mx-0 flex items-center gap-4 pt-0 pb-8" >

  <Avatar size="sm" variant="circular" src="/images/shukla-2.webp" alt="ankit shukla" className='p-2 hidden md:block'/>
  <div className="flex w-full flex-col gap-0.5 items-center md:mr-96">
  <div className="flex items-center justify-between">
  <Typography variant="h5" align="center" color="blue-gray" > Ankit Shukla </Typography>
  </div>

  <Typography color="blue-gray" align="left">Full Stack Developer</Typography>
  </div>
  </CardHeader>
  <CardBody className="mb-6 p-2">
  <Typography  align="left" className='text-lg lg:text-xl'>Hello I am Ankit Shukla A  developer, passionate of writing blog posts on most popular niches like Content Writing Tools Using Artificial Intelligence. Working as the web developer is my passion as i love to work on exciting projects. Hope the above article is helpful for you. Do not skip other posts. Good Luck!!! </Typography>
  </CardBody>
  </Card>
  </Paper>

  <h2 className='mb-16 mr-4 hover:underline md:mr-6 text-lg'>showing 6 out 40 blogs</h2>
  <div className="grid gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 p-1">

  {blogs.reverse().slice(0,visible).map((blog) => (
  <BlogItem blog={blog} key ={blog.id} />
  ))} 
          
  </div> 
  
  <div className="mt-8 mb-16 text-2xl md:mt-20 md:mb-20 lg:mb-28 md:text-3xl">
  <Link onClick={handlerLoadMore}>View More</Link>
  </div>
  </Grid>
  < Grid item xs={false} sm={false} md={1} lg={2} xl={2} />
  </Grid>
  </>
  )
  }

export default Poet;