import React from 'react';
import BlogT from './BlogT';

const Bla = ({ blogs }) =>  {
  
return(
    <>
    
    {blogs.slice(0).map((blog) => (
    <BlogT blog={blog} key ={blog.id} />
    ))} 
    
    </>  
  )
  }

      
export default Bla;