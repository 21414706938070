import React , { useState } from 'react';
import Cla from './Cla';
import  { cla }   from './Bata';

const BlogsB = () => {
  const [blogs, setBlogs] = useState(cla);

 return (
    <>
    <h1 className='text-gray-800 text-center text-2xl md:text-3xl mt-12 md:mt-16 lg:mt-20 hover:underline'><strong>Related Blogs</strong></h1>
    
    
    <p className='text-center p-1 text-lg md:text-xl mt-1 md:mt-2 lg:mt-4  mb-2 md:mb-4'>Some intresting blogs from our blog section</p>
    <div className='p-2'>
    <Cla blogs={blogs}/></div>
    </>
  );
};

export default BlogsB;