import React from "react";
import { Navbar, Collapse, Typography, Button, IconButton } from "@material-tailwind/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import Nulfye from './Nulfye.webp';
import { Link, useHistory } from 'react-router-dom';
import { Menu, MenuHandler, Avatar } from "@material-tailwind/react";



const ProfileMenu = () => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
 
 
  return (
    <Menu open={isMenuOpen} handler={setIsMenuOpen} placement="bottom-end">
      <MenuHandler>
         <Avatar
            variant="circular"
            size="md"
            alt="ankit shukla"
            className="border border-gray-900 hidden md:block"
            src="/images/shukla-2.webp"
          />      
      </MenuHandler>
     </Menu>
  );
}



export default function Header() {
  const [openNav, setOpenNav] = React.useState(false);
  const history = useHistory();

  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false),
    );
  }, []);

  const hostButtonClick = pageUrl => {
    history.push(pageUrl)
  }
 
  const navList = (
    <ul className="flex flex-col gap-8 md:gap-8 lg:gap-12 lg:mb-0 lg:mt-0 md:flex-row lg:flex-row lg:items-center ">
     
      <Typography
        as="li"
        variant="large"
        color="blue-gray"
        className="p-1 font-normal">
        <Link onClick={() => hostButtonClick ("/")} className="flex items-center hover:underline">
          Home
        </Link>
      </Typography>
      <Typography
              as="li"

        variant="large"
        color="blue-gray"
        className="p-1 font-normal"
      >
        <Link onClick={() => hostButtonClick ("/blogs")} className="flex items-center hover:underline">
          Blogs
        </Link>
      </Typography>
      <Typography
              as="li"

        variant="large"
        color="blue-gray"
        className="p-1 font-normal"
      >
        <Link onClick={() => hostButtonClick ("/about")} className="flex items-center hover:underline">
          About Us
        </Link>
      </Typography>
    </ul>
  );
 
  return (
    <div className=" w-full ">
      <Navbar className="sticky top-0 h-max bg-gray-50 rounded-none px-4 py-2 lg:px-8 lg:py-4">
        <div className="flex items-center justify-between text-blue-gray-900">
          <Typography
            as="a"
            href="/"
            className="mr-4 cursor-pointer py-1.5 font-medium"
          >
             <img
                alt="Your Company"
                src={Nulfye}
                className="h-16 w-auto "
              />
          
          </Typography>
          <div className="mr-0 hidden md:block">{navList}</div>
          <ProfileMenu />

        
          <IconButton
            variant="text"
            size="xl"
            className="md:hidden"
            onClick={() => setOpenNav(!openNav)}
           >
            {openNav ? (
              <XMarkIcon className="h-8 w-8 -mx-4 -my-4" strokeWidth={2}/>
            ) : (
              <Bars3Icon className="h-8 w-8 -mx-4 -my-4" strokeWidth={2}/>
            )}
          </IconButton>
        </div>
        <Collapse open={openNav}>
        <div className="p-2">{navList}</div>
          </Collapse>
      </Navbar>
    </div>
  );
}
